import axios from "axios";

export default class ArticulosService {
  allMyArticulos() {
    return axios.get(process.env.VUE_APP_API_PUBLIC + "articulos/mis_articulos");
  }  
  crearArticulo(data) {
    return axios.post(process.env.VUE_APP_API_PUBLIC + "articulos/create", data);
  }
  togglePublicarArticulo(data) {
    return axios.put(process.env.VUE_APP_API_PUBLIC + "articulos/publicar", data);
  }

  eliminarArticulo(data) {
    return axios.delete(process.env.VUE_APP_API_PUBLIC + "articulos/delete", {
      data: data,
    });
  }
  allArticulosForPublic() {
    return axios.get(process.env.VUE_APP_API_PUBLIC + "articulos/all");
  }
  getArticuloById(id) {
    return axios.get(process.env.VUE_APP_API_PUBLIC + "articulos/detalle/"+id);
  }  
  getArticuloByIdForEdition(id) {
    return axios.get(process.env.VUE_APP_API_PUBLIC + "articulos/detalle_for_edition/"+id);
  } 
  editarArticulo(data) {
    return axios.post(process.env.VUE_APP_API_PUBLIC + "articulos/edit", data);
  }
}
