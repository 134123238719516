<template>
  <b-container fluid>
    <h3 class="titulo-admin mt-5 mb-5">
      {{ isEdition ? "Edición" : "Creación" }} de Artículos
    </h3>
    <b-row>
      <b-col sm="12" class="mt-5 mb-5 display-flex justify-content-end">
        <label for="avatar" class="custom-file-upload">Imagen Cabecera</label>
        <input
          type="file"
          id="avatar"
          accept="image/jpeg"
          @change="onUpload($event)"
        />
        <span class="ml-3 mr-1">{{ nombreFile }}</span>
        <span
          v-if="sizeFile > 0"
          style="font-size: 12px; font-weight: 700; margin-right: 5px"
          >{{ sizeFile.toFixed(2) }}Kb</span
        >
        <img
          v-if="imagenSelectedBase64"
          :src="imagenSelectedBase64"
          alt=""
          class="imagenMuestra"
        />
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Título"
          label-for="titulo-input"
          invalid-feedback="El título es requerido"
          :state="tituloState"
        >
          <b-form-input
            id="titulo-input"
            v-model.trim="item.titulo"
            :state="tituloState"
            required
            ref="titulo"
            maxlength="250"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Autor" label-for="autor-input">
          <b-form-input
            id="autor-input"
            v-model.trim="item.autor"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Idioma" label-for="idioma-input">
          <b-form-select
            v-model="item.idioma"
            :options="optionsIdiomas"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label="Referencias" label-for="referencias-input">
          <b-form-textarea
            id="referencias-input"
            v-model.trim="item.referencias"
            placeholder="Referencias..."
            rows="1"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col sm="8">
        <b-form-group label="Resumen" label-for="resumen-input">
          <b-form-textarea
            id="resumen-input"
            v-model.trim="item.resumen"
            placeholder="Resumen del artículo..."
            rows="1"
            max-rows="6"
            maxlength="250"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <vue-editor
          v-model="item.cuerpo"
          :editorToolbar="customToolbar"
        ></vue-editor>
      </b-col>
      <b-col class="mt-5 mb-5">
        <b-button
          v-if="typeTransaction == 'create'"
          variant="primary"
          @click="crearArticulo"
          :disabled="loading"
          >Crear Artículo</b-button
        >
        <b-button
          variant="primary"
          @click="editarArticulo"
          :disabled="loading"
          v-else
          >Guardar Cambios</b-button
        >
        <b-button
          class="ml-5"
          variant="primary"
          @click="$router.push({ name: 'ArticulosAdmin' })"
          :disabled="loading"
          >Cancelar</b-button
        >
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
import ArticulosService from "@/services/articulos.service";
import { VueEditor } from "vue2-editor";
import EventBus from "@/services/eventBus.js";

export default {
  name: "articulos_creacion",
  components: {
    VueEditor,
  },
  data() {
    return {
      articulosService: null,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link"],
        ["clean"],
      ],
      id: null,
      item: {
        titulo: "",
        autor: "",
        resumen: "",
        referencias: "",
        cuerpo: "",
      },
      loading: false,
      avatarFueTocado: false,
      nombreFile: "",
      sizeFile: "",
      typeFile: 0,
      file: null,
      tipos_estado: [
        { name: "NO", value: 0 },
        { name: "SI", value: 1 },
      ],
      imagenSelectedBase64: null,
      typeTransaction: "create",
      optionsIdiomas: [
        {
          value: "spanish",
          text: this.$t(`messages.spanish`),
        },
        {
          value: "english",
          text: this.$t(`messages.english`),
        },
      ],
      tituloState: null,
    };
  },
  created() {
    this.articulosService = new ArticulosService();
    if (!!this.$route.params.id) {
      this.typeTransaction = "edit";
      this.id = this.$route.params.id;
    }
  },
  mounted() {
    if (this.typeTransaction == "edit") {
      this.getArticulo();
    }
  },
  methods: {
    getArticulo() {
      this.loading = true;
      this.articulosService
        .getArticuloByIdForEdition(this.id)
        .then((result) => {
          if (result.data.data.length == 0) {
            this.$router.push({ name: "Main" });
          } else {
            this.item = { ...result.data.data };
            if (this.item.imagen != "") {
              this.imagenSelectedBase64 = this.item.imagen;
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al recuperar el artículo",
          });
          this.loading = false;
        });
    },
    crearArticulo() {
      if (!this.validation()) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "Debes completar los campos obligatorios",
        });
        return false;
      }
      this.loading = true;
      let dataArticulo = new FormData();
      if (this.file) {
        dataArticulo.append("imagencabecera", this.file);
      }


      dataArticulo.append("datos", JSON.stringify(this.item));
      this.articulosService
        .crearArticulo(dataArticulo)
        .then((result) => {
          if (!result.data.data) {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: "Error al guardar artículo",
            });
            this.loading = false;
          } else {
            this.loading = false;
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Artículo creado satisfactoriamente",
            });
            this.$router.push({ name: "ArticulosAdmin" });
          }
        })
        .catch((err) => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al guardar artículo",
          });
          this.loading = false;
        });
    },
    editarArticulo() {
      this.loading = true;
      let dataArticulo = new FormData();
      if (this.file) {
        dataArticulo.append("imagencabecera", this.file);
      }
      
      dataArticulo.append("datos", JSON.stringify(this.item));
      this.articulosService
        .editarArticulo(dataArticulo)
        .then((result) => {
          if (!result.data.data) {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: "Error al editar artículo",
            });
            this.loading = false;
          } else {
            this.loading = false;
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Artículo editado satisfactoriamente",
            });
            this.$router.push({ name: "ArticulosAdmin" });
          }
        })
        .catch((err) => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al editar artículo",
          });
          this.loading = false;
        });
    },
    onUpload(event) {
      this.nombreFile =
        event.target.files.length == 0 ? "" : event.target.files[0].name;
      this.sizeFile =
        event.target.files.length == 0 ? 0 : event.target.files[0].size / 1024;
      this.typeFile =
        event.target.files.length == 0 ? "" : event.target.files[0].type;
      this.imagenSelectedBase64 = null;
      if (event.target.files.length == 0) {
        this.file = null;
        this.nombreFile = "";
        this.sizeFile = 0;
        this.typeFile = "";
        this.avatarFueTocado = false;
      } else if (
        this.typeFile.indexOf("jpg") == -1 &&
        this.typeFile.indexOf("jpeg") == -1
      ) {
        this.file = null;
        this.nombreFile = "";
        this.sizeFile = 0;
        this.typeFile = "";
        this.avatarFueTocado = false;
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "El archivo del avatar debe ser jpg ó jpeg",
        });
      } else if (this.sizeFile > 3096) {
        this.file = null;
        this.nombreFile = "";
        this.sizeFile = 0;
        this.typeFile = "";
        this.avatarFueTocado = false;
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "El archivo del avatar no debe ser mayor a 3Mb",
        });
      } else if (this.sizeFile <= 0) {
        this.file = null;
        this.nombreFile = "";
        this.sizeFile = 0;
        this.typeFile = "";
        this.avatarFueTocado = false;
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "El archivo del avatar debe ser jpg ó jpeg no mayor a 3Mb",
        });
      } else {
        this.file = event.target.files[0];
        this.avatarFueTocado = true;
        this.getBase64(this.file);
      }
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imagenSelectedBase64 = reader.result;
      };
      reader.onerror = (error) => {};
    },
    validation() {
      /* titulo */
      const validTitulo = this.$refs.titulo.checkValidity();
      this.tituloState = validTitulo;
      return validTitulo;
    },
  },
  computed: {
    isEdition() {
      return !!this.$route.params.id;
    },
  },
  watch: {
    $route(to) {
      to.params.id
        ? (this.typeTransaction = "edit")
        : (this.typeTransaction = "create");
      if (this.typeTransaction == "edit") {
        this.id = this.$route.params.id;
        this.getArticulo();
      } else {
        this.id = null;
        this.item = {
          titulo: "",
          autor: "",
          resumen: "",
          referencias: "",
          cuerpo: "",
        };
        this.avatarFueTocado = false;
        this.nombreFile = "";
        this.sizeFile = "";
        this.typeFile = 0;
        this.file = null;
        this.imagenSelectedBase64 = null;
      }
    },
  },
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.imagenMuestra {
  height: 150px;
}

@media screen and (max-width: 800px) {
  .imagenMuestra {
    width: 100%;
  }
}

/* .ql-align-right{
  text-align: right;
} */
</style>
